import { getBcc } from '@/api/integrations/emailsBcc';

const state = () => ({
  emailsBcc: [],
});

const getters = {
  emailsBcc: state => {
    return state.emailsBcc;
  },
};

const mutations = {
  SET_EMAIL_BCC (state, payload) {
    state.emailsBcc = payload;
  },
  ADD_EMAIL_BCC (state, email) {
    state.emailsBcc.push(email);
  },
  DELETE_EMAIL_BCC (state, deleteEmail) {
    state.emailsBcc = state.emailsBcc.filter((email) => email !== deleteEmail);
  },
};

const actions = {
  async SET_EMAIL_BCC ({ commit }, setting) {
    const emailsBcc = await getBcc(setting.provider, setting.selectedCredentialId);
    commit('SET_EMAIL_BCC', emailsBcc);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
