import { getUser } from '@/api/userMethods';
import { getSpace, getSubscribe } from '@/api/spaceMethods';
import { redirectToSetSpaceMixin } from '@/mixins/redirectToSetSpaceMixin';
import { mapGetters } from 'vuex';
import store from '@/store';

store.commit('mobile/SET_MOBILE_CHECK');
const isMobile = store.getters['mobile/isMobile'];
// redirect for existing users
const paths = {
  'credentials-add': [
    /credentials-add/,
    /billing/,
    /workspace(.)*/,
    /accept-invite/,
    /pricing(.)*/,
    /onboarding(.)*/,
    /user(.)*/,
    /privacy-policy/,
    /terms-conditions/,
    /video/,
    /CRM/,
  ],
  pricing: [
    /workspace(.)*/,
    /accept-invite/,
    /user(.)*/,
    /onboarding(.)*/,
    /pricing(.)*/,
    /CRM/,
  ],
};
// redirect for new users
const unpaidPaths = {
  billing: [
    /billing(.)*/,
    /prepaid/,
  ],
  login: [
    /auth\//,
    /\/space\//,
  ],
};
export const firstFetchMixin = {
  mixins: [redirectToSetSpaceMixin],
  computed: {
    ...mapGetters({
      credentialsList: 'credentials/credentialsList',
      isAdmin: 'account/isAdmin',
      user: 'user/user',
      account: 'account/account',
      space: 'account/space',
      theme: 'theme',
    }),
  },
  data () {
    return {
      isRedirected: false,
      beforeRoute: null,
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.beforeRoute = from;
    });
  },
  methods: {
    async redirectTo (name) {
      if (this.isAdmin) {
        Object.keys(paths).forEach(key => {
          paths[key].push(/admin(.)*/);
        });
      }
      const route = this.$route.path;
      const path = paths[name];
      if (path) {
        if (!path.some((regex) => regex.test(route))) {
          if (!this.isRedirected && this.$route.name !== name) {
            this.isRedirected = true;
            await this.$router.push({ name });
          }
        }
      } else {
        const path = unpaidPaths[name];
        if (path.some((regex) => regex.test(route))) {
          if (!this.isRedirected) {
            this.isRedirected = true;
            const redirect = this.beforeRoute?.name ? this.beforeRoute.path : '/';
            await this.$router.push(redirect);
          };
        }
      }
    },
    setIntercom (id = 'wtajtfk5') {
      // initialization Intercom
      this.$intercom.boot({
        app_id: id,
        name: this.$route.query.adminName || this.user.first_name,
        email: this.$route.query.admin || this.user.email,
        created_at: new Date(),
      });
    },
    setServiceBell (user, account, id = 'e2394e1f81b74eef8e6add957a147d0e') {
      // eslint-disable-next-line no-unused-expressions, no-var, no-sequences, no-undef
      const w = window;
      const d = document;
      function e (e, n) {
        w.ServiceBell.q = w.ServiceBell.q || [];
        w.ServiceBell.q.push([e, n]);
      }
      if (!w.ServiceBell) {
        const i = function (n) {
          for (let i = arguments.length, r = new Array(i > 1 ? i - 1 : 0), c = 1; c < i; c++) {
            r[c - 1] = arguments[c]; e(n, r);
          };
          [
            'init',
            'identify',
            'dial',
            'alert',
            'bookMeeting',
            'hide',
            'show',
            'expand',
            'collapse',
            'connect',
            'disconnect',
            'showPopup',
          ].forEach(
            function (r) {
              i[r] = function () {
                for (let i = arguments.length, r = new Array(i), c = 0; c < i; c++) {
                  r[c] = arguments[c];
                  e(n, r);
                };
              };
            });
        };
        w.ServiceBell = i;
      }
      const s = d.createElement('script');
      s.id = 'service-bell-script';
      s.src = 'https://cdn.servicebell.com/main.js';
      s.async = 1;
      const r = d.getElementsByTagName('script')[0]; r.parentNode.insertBefore(s, r);

      window.ServiceBell('init', id, { mode: 'iframe-jit' });
      window.ServiceBell('identify',
        user._id,
        {
          displayName: user.first_name + ' ' + user.last_name,
          email: user.email,
          avatar: user.avatar,
          link: account?._id ?? `${user.config.client_link}/campaigns/my-campaigns?space=${account._id}`,
        }
      );
    },
    setChatra (account) {
      // initialization Chatra
      (function (d, w, c) {
        w.ChatraID = 'EmhXonXe37yZgYY7s';
        const s = d.createElement('script');
        w[c] = w[c] || function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) d.head.appendChild(s);
      })(document, window, 'Chatra');
      // eslint-disable-next-line no-undef
      Chatra('setIntegrationData', {
        name: this.user.first_name,
        email: this.user.email,
        brand: this.user.brand,
        _id: this.user._id,
        space: account?._id,
      });

      if (this.theme === 'dark') {
        // eslint-disable-next-line no-undef
        Chatra('setColors', {
          /* main properties */
          buttonText: '#fff', /* chat button text/icon color */
          buttonBg: '#152e4d', /* chat button background color */
        });
      }
      window.ChatraSetup = {
        disabledOnMobile: true,
      };
    },
    setVendasta (url, account) {
      const script = document.createElement('script');
      script.setAttribute('src', '//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js');
      script.dataset.url = url;
      script.dataset.accountId = account;
      script.dataset.appId = 'MP-6S5KSCTK2F4W8SVHSN3QPNMLDM7TFN3L';
      document.body.appendChild(script);
    },
    setProfitWell (user) {
      window.profitwell && window.profitwell('start', { user_email: user.email });
    },
    setPartnerStack () {
      // Integration partnerstack for Buzz
      const gs = document.createElement('script');
      gs.src = 'https://js.partnerstack.com/v1/';
      gs.type = 'text/javascript';
      gs.async = 'true';
      gs.onload = gs.onreadystatechange = function () {
        const rs = this.readyState;
        if (rs && rs !== 'complete' && rs !== 'loaded') return;
        try {
          // eslint-disable-next-line no-undef
          growsumo._initialize('pk_kmeOlpKqgrRW1pzQfPEB5aPAyNxLV8UP');
          if (typeof growsumoInit === 'function') {
            // eslint-disable-next-line no-undef
            growsumoInit();
          }
        } catch (e) {}
      };
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gs, s);
    },
    async setSelectedCredential () {
      if (this.credentialsList.length) {
        let index = -1;
        const id = new URLSearchParams(window.location.search).get('selectedCred');
        if (id) {
          index = this.credentialsList.findIndex(cred => cred._id === id);
        }
        index = index > -1 ? index : 0;
        await this.$store.dispatch('credentials/SET_SELECTED_CREDENTIAL', this.credentialsList[index]);
        this.$socket.emit('change_credentials', {
          credentials: this.credentialsList[index]._id,
        });
        this.$socket.emit('join_prospects_room', {
          token: localStorage.getItem('token'),
          credentials: this.credentialsList[index]._id,
        });
      } else {
        await this.$store.dispatch('credentials/SET_SELECTED_CREDENTIAL', null);
        await this.redirectTo('credentials-add');
      }
    },
    async firstFetch (account = null, user = null) {
      account = account || await getSpace();
      user = user || await getUser();
      this.$store.dispatch('user/SET_USER', user);
      this.$store.dispatch('account/SET_ACCOUNT', account);
      if (user.brand === 'yourleadtool') {
        this.setVendasta(account.vendasta_bar.navigation_url, account.vendasta_bar.account_id);
      }

      if (user.config.brand === 'buzz') {
        this.setProfitWell(user);
      }

      if (account) {
        if (account.is_billing_enabled) {
          if (!account.is_paid) {
            if (this.$route.query.success === 'true') {
              try {
                const response = await getSubscribe();
                if (response) {
                  this.$store.commit('account/SET_SUBSCRIPT_ACCOUNT', response);
                  await this.redirectTo('billing');
                } else {
                  await this.redirectTo('pricing');
                }
              } catch (e) {
                this.$noty.error(e.message);
              }
            } else {
              await this.redirectTo('pricing');
            }
          }
        } else if (this.$route.name === 'billing') {
          this.$router.replace({ name: 'campaigns' });
        }
        await Promise.all([
          this.$store.dispatch('account/SET_IS_ADMIN'),
          this.$store.dispatch('account/SET_SPACES_LIST'),
          this.$store.dispatch('credentials/SET_CREDENTIALS_LIST'),
          this.$store.dispatch('labels/SET_LABELS_LIST'),
          this.$store.dispatch('labels/SET_SPACE_LABELS_LIST'),
          this.$store.dispatch('labels/SET_TEMPLATE_LABELS_LIST'),
          this.$store.dispatch('templates/SET_TEMPLATE_LIST'),
          // Marketplace
          // this.$store.dispatch('templates/SET_TEMPLATE_MARKETPLACE_LIST'),
        ]);
        if (!isMobile) {
          const support = user.config.support;
          const supportName = sessionStorage.getItem('support') || support?.name;
          if (supportName) {
            const supportId = sessionStorage.getItem('appId') || support.app_id;
            if (supportName !== 'no_support') {
              if (supportName === 'servicebell') {
                try {
                  this.setServiceBell(user, account);
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(e);
                }
              } else if (supportName === 'intercome') {
                this.setIntercom(supportId);
              } else if (supportName === 'freshchat') {
                try {
                  // eslint-disable-next-line no-undef
                  initiateCall(this.user);
                } catch (e) {
                }
              } else {
                this.setChatra(account);
              }
            }
          }
        }
        // setting selected credentila from url or as first in credentialsList
        await this.setSelectedCredential();
        await this.$store.dispatch('workflows/SET_WORKFLOWS_LIST');
        // redirecting from auth to main page of the app
        if (this.credentialsList.length) {
          await this.redirectTo('login');
          if (!this.space) {
            localStorage.setItem('loggedIn', 'true');
          }
        }
      } else {
        await this.$store.dispatch('account/SET_SPACES_LIST');
        await this.redirectToSetSpace();
        if (!this.space) {
          localStorage.setItem('loggedIn', 'true');
        }
      }
      this.loaded = true;
      if (this.$route.query.done === 'activation') {
        this.$noty.success('Your email was successfully confirmed');
      }
      this.loaded = true;
    },
  },
};
