import { useHttp } from '@/middleware/httpMiddleware';

async function getBcc (integration, credentialsId) {
  const data = await useHttp(
    `/integrations/${integration}/bcc`,
    'GET',
    null,
    {
      credentials_id: credentialsId,
    }
  );
  return data.payload;
}
async function addBccEmail (integration, credentialsId, email) {
  const data = await useHttp(
    `/integrations/${integration}/bcc`,
    'POST',
    {
      email,
    },
    {
      credentials_id: credentialsId,
    },
    true
  );
  return data.payload;
}
async function deleteBccEmail (integration, credentialsId, email) {
  const data = await useHttp(
    `/integrations/${integration}/bcc`,
    'DELETE',
    {
      email,
    },
    {
      credentials_id: credentialsId,
    },
    true
  );
  return data.payload;
}
export { getBcc, addBccEmail, deleteBccEmail };
