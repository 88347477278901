import { useHttp } from '@/middleware/httpMiddleware';

async function getTemplates (q = '', filters) {
  const data = await useHttp(
    '/templates/fetch',
    'GET',
    null,
    {
      labels: JSON.stringify(filters?.labels || []),
      types: JSON.stringify(filters?.types || []),
      is_favorite: filters?.is_favorite || false,
      q,
    }
  );
  return data.payload;
}

async function addTemplate (template) {
  const data = await useHttp(
    '/templates/create',
    'POST',
    {
      template_object: JSON.stringify(template),
    }
  );

  return data.payload;
}

async function updateFields (templateId, fields) {
  const data = await useHttp(
    `/templates/${templateId}/update`,
    'PUT',
    {
      template_object: JSON.stringify(fields),
    }
  );

  return data.payload;
}

async function updateFavorite (templateId, isFavorite) {
  const data = await useHttp(
    `/templates/${templateId}/update_favorite`,
    'PUT',
    {
      is_favorite: isFavorite,
    }
  );

  return data.payload;
}
async function deleteTemplate (templateId) {
  const data = await useHttp(
    `/templates/${templateId}/delete`,
    'DELETE',
    null
  );

  return data.payload;
}

async function deleteBulkTemplates (templatesId) {
  const data = await useHttp(
    '/templates/delete_bulk',
    'DELETE',
    {
      templates: JSON.stringify(templatesId),
    }
  );

  return data.payload;
}

async function updateFavoriteBulk (templatesId, isFavorite) {
  const data = await useHttp(
    '/templates/favorite_bulk',
    'PUT',
    {
      templates: JSON.stringify(templatesId),
      is_favorite: JSON.stringify(isFavorite),
    }
  );

  return data.payload;
}
export { getTemplates, addTemplate, updateFields, deleteTemplate, updateFavorite, deleteBulkTemplates, updateFavoriteBulk };
