<template>
  <article>
    <div class="flex-row-between mb-2">
      <default-list-item
        :title="userFullName"
        :text="user.email"
        :avatar-photo-link="user.avatar"
        :label-name="isAdmin ? 'Admin' : 'Member'"
      />
      <div class="flex-align-top">
        <default-label
          :label-name="isAdmin ? 'Admin' : 'Member'"
          :custom-type="isAdmin ? 'info' : 'warning'"
          size="s"
        />
      </div>
    </div>

    <div
      class="flex-row-between"
    >
      <div v-if="user.available_credentials.length">
        <p class="text_xs">
          LinkedIn Accounts
        </p>
        <user-accounts
          v-if="!isAdmin"
          :accounts="user.available_credentials"
          class="cursor-pointer"
          @click.native="modals.info = true;"
        />
        <p
          v-else
          class="text_xs"
        >
          All LinkedIn accounts on this workspace
        </p>
      </div>
      <div class="flex-align-bottom">
        <default-tooltip
          v-if="hasTooltip"
          :show.sync="showTooltip"
          data-closable="usersUser"
          :items="dataTooltip"
          :list-position="'right'"
          :has-triangle="false"
          @choose="tooltipHandler"
        >
          <default-button
            color="neutral"
            icon-name="bx-dots-vertical"
            form="circle"
            size="m"
          />
        </default-tooltip>
      </div>
    </div>
    <users-info-modal
      :show-modal.sync="modals.info"
      :user="user"
    />
    <delete-confirmation-modal
      :show.sync="modals.confirmation"
      :text="`Do you want to delete ${userFullName} from space ${currentSpace.name}?`"
      button-text="Delete from space"
      :name="'delete' + user._id"
      @confirm="exitFromSpace"
    />
  </article>
</template>
<script>
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';
  import UserAccounts from '@/components/preferencesPage/workspace/users/user/UserAccounts';
  import UsersInfoModal from '@/components/preferencesPage/workspace/users/UsersInfoModal';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';
  import { mapGetters } from 'vuex';
  import { exitFromSpace, changeAdmin } from '@/api/userMethods';

  export default {
    name: 'UsersUser',
    components: {
      DefaultListItem,
      DefaultTooltip,
      DefaultButton,
      DefaultLabel,
      UserAccounts,
      UsersInfoModal,
      DeleteConfirmationModal,
    },
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isAdmin: false,
        showTooltip: false,
        hasTooltip: false,
        adminBg: {
          color: '#1F77F2',
        },
        memberBg: {
          color: '#4F4F4F',
        },
        modals: {
          confirmation: false,
          info: false,
        },
        dataTooltip: [
          {
            name: 'Manage LinkedIn accounts Access',
            value: 'info',
          },
          {
            name: 'Delete',
            value: 'delete',
            class: 'color_danger',
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        spacesList: 'account/spacesList',
        loggedUser: 'user/user',
      }),
      userFullName () {
        return this.user.first_name + ' ' + this.user.last_name;
      },

      currentSpace () {
        return this.spacesList.find(s => s._id === this.$route.params.id);
      },

    },
    mounted () {
      this.isAdmin = this.currentSpace.admins.includes(this.user._id);
      const admin = this.currentSpace.admins.includes(this.loggedUser._id);
      const current = this.user.email === this.loggedUser.email;
      this.hasTooltip = admin || current;
      if (this.hasTooltip) {
        if (admin) {
          this.dataTooltip = [
            this.dataTooltip[0],
            {
              name: (this.isAdmin ? 'Revoke' : 'Grant') + ' Admin privileges',
              value: 'admin',
            },
            ...this.dataTooltip.slice(1),
          ];
        } else {
          this.dataTooltip = [
            {
              name: 'Delete',
              value: 'delete',
              class: 'color_danger',
            },
          ];
        }
      }
    },
    methods: {
      tooltipHandler (item) {
        switch (item.value) {
        case 'info':
          this.modals.info = true;
          break;
        case 'admin':
          this.changeAdmin();
          break;
        case 'delete':
          this.modals.confirmation = true;
          break;
        }
      },
      async changeAdmin () {
        try {
          const newSpace = await changeAdmin(this.user._id, this.currentSpace._id, !this.isAdmin);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success(`User is ${this.isAdmin ? 'revoked' : 'granted'} with admin permissions`);
        } catch (e) {
          this.$noty.error(e.message);
        };
      },
      async exitFromSpace () {
        try {
          const newSpace = await exitFromSpace(this.user._id, this.currentSpace._id);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success('User is deleted from space successfully');
        } catch (e) {
          this.$noty.error(e.message);
        };
      },
    },
  };
</script>
<style>
.flex-align-top {
  align-self: flex-start;
}
.flex-align-bottom {
  align-self: flex-end;
  margin-left: auto;
}
</style>
