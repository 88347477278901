<template>
  <div class="workspace-users__avatars">
    <default-avatar
      v-for="item in accounts"
      :key="item._id"
      class="workspace-users__avatars-item"
      :photo-link="item.photo_link"
      size="xxs"
    />
  </div>
</template>
<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  export default {
    components: {
      DefaultAvatar,
    },
    props: {
      accounts: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">
.workspace-users {
  &__avatars {
    @include flex-row;
    &-item {
      margin-left: -8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
