import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters } from 'vuex';
class LoaderOptions {
  constructor () {
    this.color = 'var(--primary-cl)';
    this.height = 90;
    this.width = 90;
    this.backgroundColor = 'var(--loader-bg)';
    this.opacity = 0.5;
    this.loader = 'dots';
    this.lockScroll = true;
    this.transition = 'fade';
    this.container = null;
    Object.assign(this, this.loaderOptions);
  }
}
export const loaderMixin = {
  watch: {
    loaded (value) {
      if (this.$refs.vldParent) {
        if (!value) {
          const options = new LoaderOptions();
          options.container = this.$refs.vldParent;
          this.loader = this.$loading.show(options);
        } else {
          this.loader?.hide();
        }
      }
    },
    loaders: {
      handler () {
        Object.entries(this.loaders).forEach(([ref, show]) => {
          if (this.$refs[ref] || ref === 'window') {
            if (show) {
              if (!this.loaderObjects[ref]) {
                const options = new LoaderOptions();
                if (ref === 'window') {
                  options.container = null;
                  options.isFullPage = true;
                } else {
                  options.container = this.$refs[ref];
                }
                this.loaderObjects[ref] = this.$loading.show(options);
              }
            } else {
              this.loaderObjects[ref]?.hide();
              this.loaderObjects[ref] = null;
            }
          }
        });
      },
      deep: true,

    },
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      user: 'user/user',
    }),
  },
  components: {
    Loading,
  },
  data () {
    return {

      loaded: true,
      loaders: {},
      loaderObjects: {},
      // loaders:{
      //   '$ref': {
      //     show: false,
      //   }
      // },
      loaderOptions: {},
      loader: null,
    };
  },
  mounted () {
  },
};
