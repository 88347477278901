<template>
  <div class="loader">
    <img
      class="loader__loader_logo-img"
      :src="loader"
    >
  </div>
</template>

<script>
  const theme = localStorage.getItem('theme');
  export default {
    name: 'LoaderFullPage',
    data () {
      return {
        loader: theme === 'dark' ? (sessionStorage.getItem('dark_loader_logo') || require('@public/logos/dark_loader_logo.png')) : (sessionStorage.getItem('loader_logo') || require('@public/logos/loader_logo.png')),
        // loader: (theme || theme === 'root') ? (sessionStorage.getItem('auth_logo') || require('@public/logos/auth_logo.png')) : (sessionStorage.getItem('dark_loader_logo') || require('@public/logos/auth_logo.png')),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .loader {
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    &__loader_logo-img {
      background-size: cover;
      height: 80px;
    }
  }
</style>
