const IntegrationsPage = () => import('@/views/integrations/IntegrationsPage');
// email
const EmailPage = () => import('@/views/integrations/emailPage/EmailPage');
const EmailWarming = () => import('@/views/integrations/emailPage/EmailWarming');
const EmailSettings = () => import('@/views/integrations/emailPage/EmailSettings');
// automation
// zapier
const ZapierPage = () => import('@/views/integrations/automation/zapierPage/ZapierPage');
const ZapierMain = () => import('@/views/integrations/automation/zapierPage/ZapierMain');
// webhooks
const WebhookPage = () => import('@/views/integrations/automation/webhookPage/WebhookPage');
const WebhookMain = () => import('@/views/integrations/automation/webhookPage/WebhookMain');
const WebhookPageSettings = () => import('@/views/integrations/automation/webhookPage/WebhookPageSettings');

const CrmPage = () => import('@/views/integrations/CRM/CrmPage');
const CrmPageDefaultState = () => import('@/views/integrations/CRM/CrmPageDefaultState');

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const integrations = [
  {
    path: '/integrations',
    name: 'integrations',
    component: IntegrationsPage,
    meta,
  },
  {
    path: '/integrations/email',
    name: 'integrations-email',
    redirect: { name: 'email-settings' },
    component: EmailPage,
    children: [
      {
        path: 'warming/:integration',
        name: 'email-warming',
        component: EmailWarming,
        meta,
      },
      {
        path: 'settings/:integration',
        name: 'email-settings',
        component: EmailSettings,
        meta,
      },
    ],
  },
  // При масштабировании нужно будет учитывать общий родительский роут под automation.
  // Возможно, объединить с email.
  {
    path: '/integrations/automation',
    name: 'zapier',
    redirect: { name: 'zapier-main' },
    component: ZapierPage,
    children: [
      {
        path: 'main/zapier',
        name: 'zapier-main',
        component: ZapierMain,
        meta,
      },
    ],
  },
  {
    path: '/integrations/automation',
    name: 'webhook',
    redirect: { name: 'webhook-main' },
    component: WebhookPage,
    children: [
      {
        path: 'main/webhook',
        name: 'webhook-main',
        component: WebhookMain,
        meta,
      },
      {
        path: 'settings/webhook',
        name: 'webhook-settings',
        component: WebhookPageSettings,
        meta,
      },
    ],
  },
  {
    path: '/integrations/CRM/manage/:integration',
    name: 'crm-manage',
    component: CrmPage,
    meta,
  },
  {
    path: '/CRM/default-state',
    name: 'defaultState',
    component: CrmPageDefaultState,
    meta,
  },
];

export default integrations;
