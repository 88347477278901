const useHttp = async (url, method = 'GET', body = null, params = {}, json = false) => {
  if (body) {
    if (json) {
      body = JSON.stringify(body);
    } else {
      const formData = new FormData();
      Object.keys(body).forEach(key => formData.append(key, body[key]));
      body = formData;
    }
  }

  const headers = {};
  if (json) {
    headers['Content-Type'] = 'application/json';
  }

  const space = new URLSearchParams(window.location.search).get('space');

  params = {
    ...params,
    token: localStorage.getItem('token'),
  };
  params = new URLSearchParams(params);

  if (space) {
    params.delete('space');
    if (space !== 'undefined' && !window.location.href.includes('/auth/')) { params.set('space', space); }
  }

  url = `${process.env.VUE_APP_BASE_URL}/public_api${url}?${params.toString()}`;

  const response = await fetch(url, {
    method,
    body,
    headers,
  });

  const data = await response.json();

  if (!response.ok || !data.ok) {
    throw new Error(data.error || 'Something went wrong');
  }

  return data;
};

export { useHttp };
