var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'ui-kit-input',
    { 'ui-kit-input_fulled': _vm.isFulled }
  ]},[_c('validation-provider',{attrs:{"vid":_vm.vid,"rules":_vm.validationString},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.description)?_c('p',{staticClass:"ui-kit-input__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_c('div',{class:[
        'ui-kit-input__container',
        {
          'ui-kit-input__container_disabled': _vm.isDisabled,
          'ui-kit-input__container_danger': errors.length && !_vm.isNude,
          'ui-kit-input__container_active': (_vm.active && !errors.length && !_vm.isNude),
          'ui-kit-input__container_nude': _vm.isNude,
        }
      ],style:(_vm.padding)},[(_vm.icon)?_c('i',{class:['bx color_tertiary', _vm.icon]}):_vm._e(),((_vm.customType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",class:[
          'ui-kit-input__item',
          'text_' + _vm.size,
        ],attrs:{"id":_vm.inputId,"name":_vm.name,"placeholder":_vm.placeholder,"readonly":_vm.readOnly || _vm.isDisabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,null)>-1:(_vm.innerValue)},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false},"input":_vm.handleInput,"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}}):((_vm.customType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",class:[
          'ui-kit-input__item',
          'text_' + _vm.size,
        ],attrs:{"id":_vm.inputId,"name":_vm.name,"placeholder":_vm.placeholder,"readonly":_vm.readOnly || _vm.isDisabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.innerValue,null)},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false},"input":_vm.handleInput,"change":function($event){_vm.innerValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",class:[
          'ui-kit-input__item',
          'text_' + _vm.size,
        ],attrs:{"id":_vm.inputId,"name":_vm.name,"placeholder":_vm.placeholder,"readonly":_vm.readOnly || _vm.isDisabled,"type":_vm.customType},domProps:{"value":(_vm.innerValue)},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false},"input":[function($event){if($event.target.composing)return;_vm.innerValue=$event.target.value},_vm.handleInput]}}),(_vm.type === 'password')?_c('i',{class:[
          'bx margin-left cursor-pointer',
          'bx-' + (_vm.showPassword ? 'hide' : 'show')
        ],on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e(),(errors.length && _vm.isNude)?_vm._l((errors),function(error,i){return _c('p',{key:`${error}${i}`,staticClass:"color_danger text_xs"},[_vm._v(" "+_vm._s(error)+" ")])}):(_vm.hasDropdown)?_c('i',{staticClass:"bx margin-left bx-chevron-down"}):_vm._e()],2),(errors.length && !_vm.isNude)?_vm._l((errors),function(error,i){return _c('p',{key:`${error}${i}`,staticClass:"color_danger text_xs"},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }